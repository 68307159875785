import {ConnectElementConfig} from '../ConnectElementList';

export const TaxRegistrationsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "taxRegistrations" */
      '../../../components/Tax/TaxRegistrations'
    ),
  enabledKey: 'tax_registrations', // Flag that will determine if the custom element should be rendered
  releaseState: 'ga',
  teamOwner: 'tax_connect_embedded_components', // Enter your team's name - this is used for SFX reporting and sentry. See docs on how to set up: https://confluence.corp.stripe.com/display/OBS/Setting+up+Sentry+Reporting#SettingupSentryReporting-SettingupaSentryproject
  htmlEvents: ['onaftertaxregistrationadded'],
  customHtmlElementMethods: {
    setDisplayCountries: {
      type: 'SupplementalObject',
      objectToSet: 'displayCountries',
      isInternal: false,
      description:
        'Sets the list of countries that can be chosen for a registration.',
    },
    setOnAfterTaxRegistrationAdded: {
      type: 'EventListenerMethod',
      event: 'onaftertaxregistrationadded',
      isInternal: false,
      description: 'Sets the event listener for when a registration is added.',
    },
  },
  description:
    'Renders the tax registrations for the account. Allows to set and remove tax registrations.',
  // docSitePath: '/connect/supported-embedded-components/issuing-cards-list',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-tax-regs-iframe',
  },
  docSitePath: '/connect/supported-embedded-components/tax-registrations',
};
