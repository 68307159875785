import {UpdateOptions} from './InitAndUpdateOptionsTypes';
import {INSTALL_STATES} from '../../components/Apps/constants';
import {EmbeddedError, EmbeddedErrorType} from '../utils/embeddedLoadErrors';
import {CustomHtmlElementMethod} from './ConnectElementList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConnectElementProperties {}

export type ConnectElementEventTypes = {
  // This event is deprecated in favor of 'exit', but is still being emitted for backwards compatibility
  onboardingexited: Record<string, unknown>;
  // Emitted when onboarding is exited
  exit: Record<string, unknown>;
  // Emitted when an account navigates to a different step or section within onboarding
  stepchange: {
    step: string;
  };

  onnotificationschange: {
    total: number;
    actionRequired: number;
  };

  // Emitted when an instant payout is created
  instantpayoutcreated: Record<string, unknown>;

  accountsessionclaimed: {
    merchantId: string;
  };

  appinstallfetched: {
    appId: string;
    state: INSTALL_STATES | null;
  };

  appinstallcompleted: {
    appId: string;
    state: INSTALL_STATES.INSTALLED | INSTALL_STATES.UNINSTALLED;
  };

  // Emitted when the payment details overlay is closed
  close: Record<string, unknown>;

  // Emitted when issuing card is loaded
  issuingcardload: Record<string, unknown>;

  // Emitted when issuing card row is loaded
  issuingcardrowload: Record<string, unknown>;

  // Emitted when a user successfully submits their financing application
  onapplicationsubmitted: Record<string, unknown>;

  // Emitted when the capital financing component loads all the merchant's financings
  onfinancingsloaded: {
    total: number;
  };

  // Emitted when the capital financing promotion component loads a merchant's offer
  oneligiblefinancingofferloaded: {
    productType: string;
  };

  // Emitted when a component fails to load
  loaderror: {
    error: EmbeddedError;
  };

  // For internal use: Emmitted when a component fails to load, with the raw error object
  _internal_loaderror: {
    error: Error;
    type: EmbeddedErrorType;
  };

  // Emitted when a component loads successfully
  loaderstart: Record<string, unknown>;

  // Internal events
  connectjsoptionsupdated: {
    values: UpdateOptions;
    appearanceOptionsOrder: string[];
    invalidAppearanceTokens: string[];
  };
  debugUtilsTestEvent: {
    debugUtilsTestValue: string;
  };

  // Emitted when the account management form section is closed
  accountmanagementformclose: Record<string, unknown>;

  // Emitted when a new tax registration is added
  onaftertaxregistrationadded: {
    id: string;
  };

  // Emitted when tax settings are updated
  ontaxsettingsupdated: {
    id: string;
  };
};

export type ConnectElementEventType = keyof ConnectElementEventTypes;

export type ConnectElementEventValue =
  ConnectElementEventTypes[ConnectElementEventType];

export interface ICalculatedConnectElementProperties
  extends IConnectElementProperties {
  elementTagName: string;
}

export const commonHtmlEventMethods: Record<string, CustomHtmlElementMethod> = {
  setAccountSessionClaimed: {
    type: 'EventListenerMethod',
    event: 'accountsessionclaimed',
    isInternal: true,
    description:
      'Sets the event listener for when an account session is claimed. This returns the connected account ID used for analytics with the mobile SDK.',
  },
  setOnLoadError: {
    type: 'EventListenerMethod',
    event: 'loaderror',
    isInternal: false,
    description: 'Sets the event listener for when a component fails to load',
  },
  setOnLoaderStart: {
    type: 'EventListenerMethod',
    event: 'loaderstart',
    isInternal: false,
    description:
      'Sets the event listener for when a component starts to display UI',
  },
};
