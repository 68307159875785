import {ConnectElementConfig} from '../ConnectElementList';

export const TaxSettingsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "taxSettings" */
      '../../../components/Tax/TaxSettings'
    ),
  enabledKey: 'tax_settings', // Flag that will determine if the custom element should be rendered
  releaseState: 'ga',
  teamOwner: 'tax_connect_embedded_components', // Enter your team's name - this is used for SFX reporting and sentry. See docs on how to set up: https://confluence.corp.stripe.com/display/OBS/Setting+up+Sentry+Reporting#SettingupSentryReporting-SettingupaSentryproject
  htmlEvents: ['ontaxsettingsupdated'],
  customHtmlElementMethods: {
    setDisplayHeadOfficeCountries: {
      type: 'SupplementalObject',
      objectToSet: 'displayHeadOfficeCountries',
      isInternal: false,
      description:
        'Sets the list of countries that can be chosen for a head office.',
    },
    setHideProductTaxCodeSelector: {
      type: 'SupplementalObject',
      objectToSet: 'hideProductTaxCodeSelector',
      isInternal: false,
      description: 'Disables the ability to set a default product tax code.',
    },
    setOnTaxSettingsUpdated: {
      type: 'EventListenerMethod',
      event: 'ontaxsettingsupdated',
      isInternal: false,
      description: 'Sets the event listener for when tax settings are updated.',
    },
  },
  description:
    'Allows editing the tax settings (tax address and tax code) for the given Connected account.',
  docSitePath: '/connect/supported-embedded-components/tax-settings',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-tax-sets-iframe',
  },
};
