import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const dataSourceParameter = 'data-source' as const;
const recipientParams = [dataSourceParameter] as const;

type DataSourceValues = 'customers' | 'recipients';
type RecipientsParams = (typeof recipientParams)[number];
export type RecipientsHtmlParams = Partial<
  Record<RecipientsParams, DataSourceValues>
>;

export const RecipientsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "treasuryRecipients" */
      '../../../components/Treasury/RecipientsList'
    ),
  enabledKey: 'recipients',
  releaseState: 'preview',
  htmlAttributes: {
    [dataSourceParameter]: {
      isInternal: false,
    },
  },
  customHtmlElementMethods: {
    setDataSource: {
      type: 'AttributeSettingMethod',
      attribute: dataSourceParameter,
      isInternal: false,
      description: 'Sets the data source to use when displaying recipients.',
    },
  },
  teamOwner: 'ef_surfaces',
  description: 'Displays the recipients of a Treasury financial account.',
};
