import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

export const defaultFinancingOfferParameter =
  'default-financing-offer' as const;
export const showFinancingSelectorParameter =
  'show-financing-selector' as const;
export const faqUrlParameter = 'faq-url' as const;
export const supportUrlParameter = 'support-url' as const;

export const CapitalFinancingConfig: ConnectElementConfig = {
  Component: () => import('../../../components/Capital/CapitalFinancing'),
  enabledKey: 'capital_financing',
  enabledOverrideFlag: ComponentOverrideFlag.capital_financing,
  htmlAttributes: {
    [defaultFinancingOfferParameter]: {
      isInternal: false,
    },
    [showFinancingSelectorParameter]: {
      isInternal: false,
    },
    [faqUrlParameter]: {
      isInternal: false,
    },
    [supportUrlParameter]: {
      isInternal: false,
    },
  },
  htmlEvents: ['onfinancingsloaded'],
  customHtmlElementMethods: {
    setDefaultFianncingOffer: {
      type: 'AttributeSettingMethod',
      attribute: defaultFinancingOfferParameter,
      isInternal: false,
      description: 'Sets the default financing offer to display',
    },
    setShowFinancingSelector: {
      type: 'AttributeSettingMethod',
      attribute: showFinancingSelectorParameter,
      isInternal: false,
      description:
        'Controls displaying the financing dropdown selector to allow the merchant to change the focused financing',
    },
    setFaqUrl: {
      type: 'AttributeSettingMethod',
      attribute: faqUrlParameter,
      isInternal: false,
      description: 'Sets the FAQ url to display',
    },
    setSupportUrl: {
      type: 'AttributeSettingMethod',
      attribute: supportUrlParameter,
      isInternal: true,
      description: 'Sets the support url to display',
    },
    setOnFinancingsLoaded: {
      type: 'EventListenerMethod',
      event: 'onfinancingsloaded',
      isInternal: false,
      description:
        'Sets the event listener that allows a user to pass in a callback function that provides a total financings loaded count.',
    },
  },
  releaseState: 'preview',
  teamOwner: 'capital_for_platforms',
  description:
    'Shows balance and reporting information for active accepted loans or past accepted loans',
};
