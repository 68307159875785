import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

export const layoutParameter = 'layout' as const;
export const privacyPolicyUrlParameter = 'privacy-policy-url' as const;
export const eligibilityCriteriaUrlParameter =
  'eligibility-criteria-url' as const;
export const howCapitalWorksUrlParameter = 'how-capital-works-url' as const;
export const debugParameter = 'debug' as const;

export const CapitalFinancingPromotionConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "capitalFinancingPromotionExperience" */
      '../../../components/Capital/CapitalFinancingPromotion'
    ),
  enabledKey: 'capital_financing_promotion',
  enabledOverrideFlag: ComponentOverrideFlag.capital_financing_promotion,
  htmlAttributes: {
    [layoutParameter]: {
      isInternal: false,
    },
    [privacyPolicyUrlParameter]: {
      isInternal: false,
    },
    [eligibilityCriteriaUrlParameter]: {
      isInternal: false,
    },
    [howCapitalWorksUrlParameter]: {
      isInternal: false,
    },
    [debugParameter]: {
      isInternal: true,
    },
  },
  htmlEvents: ['onapplicationsubmitted', 'oneligiblefinancingofferloaded'],
  customHtmlElementMethods: {
    setLayout: {
      type: 'AttributeSettingMethod',
      attribute: layoutParameter,
      isInternal: false,
      description:
        'Sets the layout mode of the component. Valid options are "banner" or "full".',
    },
    setPrivacyPolicyUrl: {
      type: 'AttributeSettingMethod',
      attribute: privacyPolicyUrlParameter,
      isInternal: false,
      description:
        "Customizes the URL used for the Privacy Policy link in the application component. If not set, will default to Stripe's Privacy Policy.",
    },
    setEligibilityCriteriaUrl: {
      type: 'AttributeSettingMethod',
      attribute: eligibilityCriteriaUrlParameter,
      isInternal: false,
      description:
        "Customizes the URL used for links to eligibility criteria documentation in the promotion component. If not set, will default to Stripe Capital's Eligibility Criteria.",
    },
    setHowCapitalWorksUrl: {
      type: 'AttributeSettingMethod',
      attribute: howCapitalWorksUrlParameter,
      isInternal: false,
      description:
        "Customizes the URL used for links to documentation about the Capital program in the promotion component. If not set, will default to Stripe Capital's documentation.",
    },
    setOnApplicationSubmitted: {
      type: 'EventListenerMethod',
      event: 'onapplicationsubmitted',
      isInternal: false,
      description:
        'Sets the event listener that allows a user to pass in a callback function for when a financing application is submitted.',
    },
    setOnEligibleFinancingOfferLoaded: {
      type: 'EventListenerMethod',
      event: 'oneligiblefinancingofferloaded',
      isInternal: false,
      description:
        'Sets the event listener that allows a user to pass in a callback function for when a financing offer is loaded.',
    },
  },
  releaseState: 'preview',
  teamOwner: 'capital_for_platforms',
  description:
    "Displays information about the merchant's eligible financing offers and can open the application form.",
};
