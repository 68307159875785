import {
  ConnectElementEventTypes,
  ICalculatedConnectElementProperties,
} from './ConnectJSInterface/HtmlEventTypes';

export type IConnectElementEvent = {
  eventType: keyof ConnectElementEventTypes;
  detail: ConnectElementEventTypes[keyof ConnectElementEventTypes];
};

export type EmitEvent = <T extends keyof ConnectElementEventTypes>(
  eventType: T,
  detail: ConnectElementEventTypes[T],
) => void;

export class ConnectElementEventEmitter {
  /**
   * Controls whether the loaderror event has already been emitted.
   * Load errors can cascade and the platform and Stripe only need to be directly notified of the first one.
   */
  private hasAlreadyEmittedLoadError = false;

  /**
   * Controls whether the _internal_loaderror event has already been emitted.
   * Load errors can cascade and Stripe only needs to be directly notified of the first one.
   */
  private hasAlreadyEmittedInternalLoadError = false;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private dispatchEvent: (event: CustomEvent) => void,
    private elementTagName: string,
  ) {
    // Empty
  }

  emitEvent: EmitEvent = (eventType, detail) => {
    if (eventType === 'loaderror') {
      if (this.hasAlreadyEmittedLoadError) {
        return;
      }

      this.hasAlreadyEmittedLoadError = true;
    }

    if (eventType === '_internal_loaderror') {
      if (this.hasAlreadyEmittedInternalLoadError) {
        return;
      }

      this.hasAlreadyEmittedInternalLoadError = true;
    }

    // Translate the event into a CustomEvent
    const event = new CustomEvent<ICalculatedConnectElementProperties>(
      eventType,
      {
        detail: {...detail, elementTagName: this.elementTagName},
      },
    );
    this.dispatchEvent(event);
  };
}
