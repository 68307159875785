import {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const recipientsParameter = 'recipients' as const;
const recipientsParams = [recipientsParameter] as const;

type RecipientsParams = (typeof recipientsParams)[number];
export type RecipientsHtmlParams = Partial<Record<RecipientsParams, string>>;

export const RecipientsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "treasuryRecipients" */
      '../../../components/Treasury/RecipientsList'
    ),
  enabledKey: 'recipients',
  releaseState: 'internal',
  htmlAttributes: {},
  customHtmlElementMethods: {},
  teamOwner: 'ef_surfaces',
  description: 'Displays the recipients of a Treasury financial account.',
};
