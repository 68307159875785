import {message} from '@sail/intl';
import {ConnectElementConfig} from '../ConnectElementList';

const messages = {
  errorText: message({
    id: 'connectEmbed.reportingChart.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your information cannot be loaded here.',
    description: 'Message shown when an unhandled reporting error is caught',
  }),
};

export const reportingChartParameter = 'reporting-chart' as const;
const reportingChartParams = [reportingChartParameter] as const;

type ReportingChartParams = (typeof reportingChartParams)[number];
export type ReportingChartHtmlParams = Partial<
  Record<ReportingChartParams, string>
>;

export const ReportingChartConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "reportingChart" */
      '../../../components/Reporting/ReportingChart'
    ),
  messageOverrides: messages,
  enabledKey: 'reporting_chart',
  releaseState: 'internal',
  htmlAttributes: {},
  customHtmlElementMethods: {},
  teamOwner: 'reporting_connect_embedded_components',
  description: 'Displays the reporting components.',
};
