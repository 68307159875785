import {message} from '@sail/intl';
import {ConnectElementConfig} from '../ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

const messages = {
  errorText: message({
    id: 'connectEmbed.documents.errorBoundary.text',
    defaultMessage: 'Your documents cannot be loaded.',
    description:
      'Message shown when an unhandled documents center error is caught',
  }),
};

export const DocumentsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "documents" */ '../../../components/Documents/DocumentsList'
    ),
  messageOverrides: messages,
  releaseState: 'ga',
  enabledKey: 'documents',
  enabledOverrideFlag: ComponentOverrideFlag.documents,
  teamOwner: 'accounts_ui',
  description:
    'Displays the list of documents that a Connected account has. Currently it delivers only tax invoices.',
  docSitePath: '/connect/supported-embedded-components/documents',
  syntheticTestInfo: {
    testUrl: 'https://amp.corp.stripe.com/synthetics/us-west-2/sus-documents',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-docs-iframe-rc',
  },
};
