import {ConnectElementConfig} from '../ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';
import {RECENT_PAYOUTS_HTML_ATTRIBUTE} from '../HtmlAttributes';

const payoutsListComponentParams = [RECENT_PAYOUTS_HTML_ATTRIBUTE] as const;
type PayoutsListComponentParams = (typeof payoutsListComponentParams)[number];
export type PayoutsListComponentHtmlParams = Partial<
  Record<PayoutsListComponentParams, string>
>;

export const PayoutsListConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "payoutsList" */ '../../../components/Payouts/PayoutsList/PayoutsListV2'
    ),
  enabledKey: 'payouts_list',
  enabledOverrideFlag: ComponentOverrideFlag.payouts_list,
  htmlAttributes: {
    // Internal - to enable these for platforms, we'll need API review
    [RECENT_PAYOUTS_HTML_ATTRIBUTE]: {
      isInternal: true,
    },
  },
  releaseState: 'ga',
  teamOwner: 'accounts_ui',
  description: 'Displays the list of payouts.',
  docSitePath: '/connect/supported-embedded-components/payouts-list',
};
