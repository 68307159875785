import {platformUrlParamName} from '@stripe-internal/connect-embedded-lib';
import {ConnectElementImportKeys} from '../connect/ConnectJSInterface/ConnectElementList';
import {getFlagOverrides} from '../utils/flags/flagOverrides';
import {getCurrentScriptUrlContext} from '../utils/getCurrentScriptUrlContext';
import {getStronglyTypedEntries} from '../utils/getStronglyTypedEntries';
import {runningInIframeParamName} from './utils/isRunningInIframe';
import {disableAnalyticsParamName} from '../data-layer-client/buildDataLayerFrame';
import {isAnalyticsDisabled} from '../data-layer-frame/isAnalyticsDisabled';
import {getDebugComponent} from './getDebugComponent';
import {getCurrentPageViewId} from '../data-layer-client/Metrics';

export const embeddedComponentParamName = 'embeddedComponent';
export const pageViewIdParamName = 'pageViewId';

export const buildUILayerFrameUrl = (
  absoluteFolderPath: string,
  flagOverrides: Record<string, boolean>,
  platformUrl: string,
  connectElement: ConnectElementImportKeys,
) => {
  const uiLayerUrl = new URL(
    // eslint-disable-next-line camelcase
    `${absoluteFolderPath}/ui_layer_${__webpack_hash__}.html`,
  );

  const searchParams = new URLSearchParams();
  searchParams.set(embeddedComponentParamName, connectElement);

  // Pipe all of the flags through
  getStronglyTypedEntries(flagOverrides).forEach((entry) => {
    searchParams.set(entry[0], entry[1].toString());
  });

  // Set platform URL so that getFlagOverrides knows whether the platform page is an internal domain
  searchParams.set(platformUrlParamName, platformUrl);

  // Set runningInIframe so that we can check whether we are running in an iframe
  searchParams.set(runningInIframeParamName, 'true');

  // Set analytics disabled param
  searchParams.set(disableAnalyticsParamName, isAnalyticsDisabled().toString());

  searchParams.set(pageViewIdParamName, getCurrentPageViewId());

  // Set debug port if we are debugging the component
  const debugComponent = getDebugComponent(platformUrl);
  if (debugComponent === connectElement) {
    const debugPort = new URL(location.href).searchParams.get(
      'react_debug_port',
    );
    searchParams.set('react_debug_port', debugPort || '8097');
  }

  uiLayerUrl.hash = searchParams.toString();
  return uiLayerUrl;
};

export const buildUILayerFrame = (
  name: string,
  connectElement: ConnectElementImportKeys,
): HTMLIFrameElement => {
  const scriptUrlContext = getCurrentScriptUrlContext();

  const flagOverrides = getFlagOverrides();
  const uiLayerUrl = buildUILayerFrameUrl(
    scriptUrlContext.absoluteFolderPath,
    flagOverrides,
    window.location.href,
    connectElement,
  );

  const iframe = document.createElement('iframe');
  iframe.name = name;

  // Allows Gelato to request camera access
  iframe.setAttribute('allow', 'camera');

  iframe.style.display = 'block';
  // We use 1px here since FireFox was not triggering resize events/rendering when the iframe is only 0px (see: https://bugzilla.mozilla.org/show_bug.cgi?id=1749661)
  iframe.style.height = '1px'; // Unset any height attribute that could be set before
  iframe.style.width = '100%';
  iframe.src = uiLayerUrl.toString();

  // iframe style resets
  iframe.style.background = 'transparent';
  iframe.style.border = 'none';
  iframe.style.borderRadius = '0px';
  iframe.style.borderWidth = '0px';
  iframe.style.boxSizing = 'content-box'; // This one is important for positioning and sizing
  iframe.style.cursor = 'auto';
  iframe.style.margin = '0px';
  iframe.style.maxHeight = 'none';
  iframe.style.maxWidth = 'none';
  iframe.style.minHeight = 'none';
  iframe.style.minWidth = 'none';
  iframe.style.opacity = '1';
  iframe.style.outline = 'none';
  iframe.style.padding = '0px';
  iframe.style.pointerEvents = 'auto';
  iframe.style.position = 'relative';
  iframe.style.transform = 'none';
  iframe.style.transition = 'none';
  iframe.style.userSelect = 'auto';
  iframe.style.verticalAlign = 'baseline';
  iframe.style.visibility = 'visible';

  // Hide scrollbar
  iframe.style.overflow = 'hidden';
  iframe.scrolling = 'no';

  iframe.setAttribute(
    'data-testid',
    `stripe-connect-ui-layer-${connectElement}`,
  );
  iframe.setAttribute(
    'aria-label',
    `stripe-connect-ui-layer-${connectElement}`,
  );

  return iframe;
};
