import {ConnectElementConfig} from '../ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

const INSTANT_PAYOUTS_HTML_ATTRIBUTE = 'instant-payouts' as const;

const balancesComponentParams = [INSTANT_PAYOUTS_HTML_ATTRIBUTE] as const;
type PayoutsBalanceComponentParams = (typeof balancesComponentParams)[number];
export type PayoutsBalanceComponentHtmlParams = Partial<
  Record<PayoutsBalanceComponentParams, string>
>;

export const BalancesConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "balances" */ '../../../components/Payouts/Balance/Balance'
    ),
  enabledKey: 'balances',
  enabledOverrideFlag: ComponentOverrideFlag.balances,
  htmlAttributes: {
    [INSTANT_PAYOUTS_HTML_ATTRIBUTE]: {
      isInternal: true,
    },
  },
  releaseState: 'ga',
  teamOwner: 'accounts_ui',
  description:
    'Renders the balance for the account. Allows for manual payouts, instant payouts, and management of payout schedule.',
  docSitePath: '/connect/supported-embedded-components/balances',
};
