import CounterWithAreaCount from 'src/internal/platformMetrics/aggregators/CounterWithAreaCount';

export const safeParams = [
  'org_access_provider_experiments',
  'org_experiments_dialog',
  'org_experiments_hook',
  'org_experiments',
  'org_flags',
  'org_merchant_hook',
  'org_merchant',
  'org_polyjuice_hook',
  'org_polyjuice',
  'org_set_user_preferences',
  'org_user_preferences',
  'org_v1_permission_set',
  'org_v1_permission',
] as const;

type SafeParams = (typeof safeParams)[number];

export default new CounterWithAreaCount<SafeParams>(
  'frontend.platform-ui.org-globals-usage-count',
);
